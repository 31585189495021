import { useLayoutEffect, useMemo } from 'react';

import { CrossOriginStorageHub } from '../../components/CrossOriginStorage';
import { Error404Page } from '../../Error';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';

function CrossStorageHubInternal(props: {
  origin: {
    hub: string;
    client: string;
  };
}): JSX.Element | null {
  const hub = useMemo(
    () =>
      new CrossOriginStorageHub(
        [props.origin.client],
        logger.scoped('cross-storage-hub')
      ),
    [props.origin.client]
  );

  useLayoutEffect(() => {
    logger.verbose(getFeatureQueryParam('verbose-local-logging'));
    return hub.on();
  }, [hub]);

  return null;
}

export function CrossStorageHub(props: {
  origin: {
    hub: string;
    client: string;
  };
}): JSX.Element | null {
  if (window.location.origin !== props.origin.hub) {
    return <Error404Page capture />;
  }
  return <CrossStorageHubInternal {...props} />;
}
