import { useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type PairingGame } from '../../../types';

export interface UsePairingGameReturned {
  pairingGame: PairingGame | undefined;
  isLoading: boolean;
  error: Error;
}

export function usePairingGame(props: {
  pairingId?: string | null;
}): UsePairingGameReturned {
  const { data, error, isValidating } = useSWR(
    props.pairingId ? `/pairing/${props.pairingId}` : null,
    async () =>
      (await apiService.pairing.getPairingGame(props.pairingId || '')).data
        .pairingGame,
    {
      shouldRetryOnError: false,
    }
  );

  return useMemo(
    () => ({
      pairingGame: data,
      isLoading: !data && isValidating,
      error: error,
    }),
    [data, error, isValidating]
  );
}
