import { Navigate, useParams } from '@remix-run/react';
import { useTitle } from 'react-use';

import { makeTitle } from '../../../utils/common';
import { GlobalLoading } from '../../GlobalLoading';
import { getVenueSlug } from '../../Venue/VenueProvider';
import { usePairingGame } from './hooks';

export const PairingLandingPage = (): JSX.Element => {
  useTitle(makeTitle('Pairs'));

  const { pairingId } = useParams<'pairingId'>();
  const trimmedPairingId = pairingId?.replaceAll('*', '');
  const { pairingGame, isLoading } = usePairingGame({
    pairingId: trimmedPairingId,
  });

  if (isLoading || !pairingGame) return <GlobalLoading />;

  const search = new URLSearchParams({
    'featured-game': pairingGame.nextGamePack.id,
    'pairing-id': pairingGame.pairingId,
  });

  return (
    <Navigate
      to={{
        pathname: `/venue/${getVenueSlug(pairingGame)}`,
        search: search.toString(),
      }}
    />
  );
};
