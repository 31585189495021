import { type MetaFunction } from '@remix-run/node';
import { Suspense } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { Component as AppRoutes } from '../../src/AppRoutes.client';
import { GlobalLoading } from '../../src/components/GlobalLoading';
import { makeTitle } from '../../src/utils/common';
import { PageAnalyticsSetup } from '../components/PageAnalyticsSetup';

export default function Component() {
  return (
    <div
      data-debugid='shell-app-routes'
      style={{
        width: '100vw',
        height: '100%',
        position: 'fixed',
        background: 'black',
      }}
    >
      <PageAnalyticsSetup>
        <ClientOnly>
          {() => (
            <Suspense fallback={<GlobalLoading debug='shell-app-routes' />}>
              <AppRoutes />
            </Suspense>
          )}
        </ClientOnly>
      </PageAnalyticsSetup>
    </div>
  );
}

export const meta: MetaFunction = () => {
  const desc =
    'Company Culture Reimagined For Modern Teams, Leverage the power of hosted social games and icebreakers to boost team engagement.';

  return [
    { title: makeTitle('') },
    { 'og:title': 'Luna Park' },
    { 'og:description': desc },
    { description: desc },
  ];
};
