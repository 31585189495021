import { Controller, useForm } from 'react-hook-form';

import logo from '../../assets/img/logo.svg';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { LegalDisclaimer } from '../common/Utilities';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { useUser, useUserContext } from '../UserContext';
import { useRedirectTo } from './hooks';
import { MarketingLayout } from './MarketingLayout';

function NotificationCheckbox(props: {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleChange = async (value: boolean) => {
    if (value) {
      props.onChange(true);
      return;
    }

    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='p-5 text-white text-center'>
          <p className='text-2xl font-medium'>
            Are you sure you don’t want to receive important notices?
          </p>
          <p className='mt-4 text-sms'>
            These notices via email and slack help to facilitate the experience.
            We make it easy to unsubscribe later if it’s not working for you.
          </p>
        </div>
      ),
      confirmBtnLabel: '😢 Opt Out',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return;

    props.onChange(false);
  };

  return (
    <input
      type={'checkbox'}
      className='checkbox-dark'
      name={props.name}
      checked={props.value}
      onChange={(e) => handleChange(e.target.checked)}
    />
  );
}

interface FormData {
  firstName: string;
  lastName: string;
  notificationEnabled: boolean;
}

const maxNameLength = 50;

function ActivationForm(props: {
  defaultValues?: FormData;
  onSubmit: (data: FormData) => Promise<void>;
  formError?: Error | null;
}): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: props.defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(props.onSubmit)}
      className='w-full flex flex-col gap-2.5'
    >
      <fieldset disabled={isSubmitting} className='flex flex-col gap-2.5'>
        <label className='w-full'>
          <input
            className={`w-full h-12.5 ${
              errors.firstName ? 'field-error' : 'field'
            } mb-0`}
            maxLength={maxNameLength}
            placeholder='First Name'
            {...register('firstName', {
              required: true,
              maxLength: maxNameLength,
            })}
          />
          {errors.firstName && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs'>
              Must be 1 to {maxNameLength} characters
            </div>
          )}
        </label>

        <label className='w-full'>
          <input
            className={`w-full h-12.5 ${
              errors.lastName ? 'field-error' : 'field'
            } mb-0`}
            maxLength={maxNameLength}
            placeholder='Last Name'
            {...register('lastName', {
              required: true,
              maxLength: maxNameLength,
            })}
          ></input>
          {errors.lastName && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs'>
              Must be 1 to {maxNameLength} characters
            </div>
          )}
        </label>
      </fieldset>

      {props.formError && (
        <div className='px-2 pt-1 text-left text-red-005 text-3xs'>
          {err2s(props.formError)}
        </div>
      )}

      <div className='bg-dark-gray h-10 rounded-xl mt-5 w-full mb-1'>
        <button
          type='submit'
          disabled={isSubmitting || !isValid}
          className='btn-primary px-12 h-10 flex justify-center items-center gap-2 w-full'
        >
          {isSubmitting && <Loading text='' />}
          Continue
        </button>
      </div>

      <label className='w-full flex items-center gap-2 mt-5 mb-1'>
        <Controller
          control={control}
          name='notificationEnabled'
          render={({ field }) => (
            <NotificationCheckbox
              name='notificationEnabled'
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <p className='text-sms font-normal text-secondary'>
          Receive occasional & important notices like game launches
        </p>
      </label>

      <LegalDisclaimer text='By joining Luna Park' className='text-left' />
    </form>
  );
}

function MainContent(props: {
  organizer: Organizer;
  organization: Organization;
}): JSX.Element {
  const { updateUser } = useUserContext();

  const {
    call: handleSubmit,
    state: { error },
  } = useLiveAsyncCall(async (data: FormData) => {
    if (!data.notificationEnabled) {
      // disable notification first to avoid sending onboarding emails
      await apiService.notification.UpdateSettings({
        value: {
          disabled: true,
        },
      });
    }

    const resp = await apiService.organization.updateOrganizer(
      props.organizer.orgId,
      props.organizer.uid,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    );
    if (resp)
      updateUser({
        username: data.firstName,
        organizer: resp.data.organizer,
      });
  });

  return (
    <div
      className={`
          h-full w-full
          flex flex-col justify-center 
          mx-auto p-6
          sm:max-w-130
          isolate
        `}
    >
      <div className='z-5 bg-black bg-opacity-80 p-10 rounded-xl'>
        <img src={logo} className='w-12 -ml-2 lg:hidden' alt='Luna Park Logo' />

        <header className='mt-4 flex flex-col gap-1'>
          <div className='text-white tracking-wider text-xs lg:text-sm   font-medium'>
            Your Organization
          </div>
          <div className='text-tertiary text-3xl lg:text-4xl font-semibold'>
            {props.organization.name}
          </div>
          <div className='text-white text-sm'>
            Join{' '}
            <strong>
              {new Intl.NumberFormat().format(
                props.organization.organizersCount
              )}
            </strong>{' '}
            of{' '}
            <span className='hidden lg:inline'>
              your coworkers playing games on Luna Park.
            </span>
            <span className='lg:hidden'>
              your coworkers playing games on Luna Park, the world’s first
              gaming platform for teams.
            </span>
          </div>
        </header>

        <div className='text-white font-bold mt-10 mb-4'>
          Let’s activate your account.
        </div>

        <ActivationForm
          defaultValues={{
            firstName: props.organizer.firstName,
            lastName: props.organizer.lastName,
            notificationEnabled: true,
          }}
          onSubmit={handleSubmit}
          formError={error}
        />
      </div>
    </div>
  );
}

export function UserActivation(): JSX.Element | null {
  const redirectTo = useRedirectTo();
  const user = useUser({ init: true });
  const organizer = user.organizer;
  const organization = user.organizer?.organization;

  if (!organizer || !organization) {
    return null;
  }

  if (organizer.activated) {
    window.location.replace(redirectTo ?? '/home');
    return <></>;
  }

  return (
    <ConfirmCancelModalProvider>
      <MarketingLayout>
        <MainContent organizer={organizer} organization={organization} />
      </MarketingLayout>

      <ConfirmCancelModalRoot />
    </ConfirmCancelModalProvider>
  );
}
