import lpLogo from '../../assets/img/lp-3d-logo.png';
import { type Organizer } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { GlobalLoading } from '../GlobalLoading';
import { useIsMyOrganizationFree } from '../Organization';
import { useIsUserLoaded, useUser } from '../UserContext';

const BACKGROUND_VIDEO = getStaticAssetPath('videos/lightspeed-gray.mp4');
const HIGH_FIVE_ICON = getStaticAssetPath('images/high-five.png');

function FreemiumMobileAccessDenied(props: { organizer: Organizer }) {
  return (
    <div className='w-full flex flex-col items-center'>
      <img src={lpLogo} alt='Luna Park' className='w-full'></img>
      <div className='text-2xl font-bold text-center mb-6'>
        High Five {props.organizer.firstName}!
      </div>
      <img src={HIGH_FIVE_ICON} alt='High Five' className='w-30'></img>
      <div className='text-xl font-bold text-center mt-3'>
        Welcome to Luna Park!
      </div>
      <div className='mt-6 font-medium break-words'>
        Watch your email for more information about your account.
      </div>
      <div className='mt-5 font-medium break-words'>
        Luna Park must be accessed from a desktop or laptop computer using
        Google Chrome, Mozilla Firefox, or Microsoft Edge.{` `}
        <strong>Don’t worry</strong>, we’ll send you everything you need to get
        started right away!
      </div>
    </div>
  );
}

function GeneralMobileAccessDenied() {
  return (
    <div className='w-full flex flex-col items-center pt-20'>
      <img src={lpLogo} alt='Luna Park' className='w-full'></img>
      <div className='text-center font-medium break-words'>
        Hi! It looks like you are trying to access Luna Park from a mobile
        device. Luna Park only works on a laptop or desktop computer. To access
        your event, please close this and visit the same link using the latest
        version of Google Chrome, Mozilla Firefox, or Microsoft Edge on a laptop
        or desktop.
      </div>
    </div>
  );
}

const MobileAccessDenied = (): JSX.Element => {
  const user = useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const isFree = useIsMyOrganizationFree();

  if (!isUserLoaded) return <GlobalLoading />;
  return (
    <div className='relative w-screen h-screen bg-black text-white'>
      <div className='absolute left-0 top-0 bottom-0 right-0 w-full z-5'>
        <video
          className='absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/3'
          src={BACKGROUND_VIDEO}
          autoPlay
          playsInline
          loop
          muted
        />
      </div>

      <div className='absolute z-10 w-full h-full px-10 py-5 overflow-auto scrollbar'>
        {isFree && user.organizer ? (
          <FreemiumMobileAccessDenied organizer={user.organizer} />
        ) : (
          <GeneralMobileAccessDenied />
        )}
      </div>
    </div>
  );
};

export { MobileAccessDenied };
