import { useNavigate } from '@remix-run/react';
import { Field, Formik, type FormikProps, type FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';

import logo from '../../assets/img/logo-text.svg';
import { useUserRedirect } from '../../hooks/useRedirection';
import { apiService } from '../../services/api-service';
import { type APIError } from '../../types/api';
import { isValidEmail, makeTitle } from '../../utils/common';
import { Modal } from '../common/Modal';
import { LegalDisclaimer } from '../common/Utilities';
import { PortalLink } from '../PortalContext';
import { useUserContext } from '../UserContext';
import {
  buildSearchParamsWithRedirectToAsString,
  useRedirectTo,
} from './hooks';

interface SignupFormValues {
  email?: string;
  username?: string;
  password?: string;
}

const SignupForm = (): JSX.Element => {
  useTitle(makeTitle('Signup'));
  const navigate = useNavigate();
  const [error, setError] = useState<APIError | null>(null);
  const [inited, setInited] = useState(false);
  const redirect = useUserRedirect();
  const ctx = useUserContext();
  const redirectTo = useRedirectTo();

  useEffect(() => {
    async function init() {
      const redirected = await redirect([
        { kind: 'admin', target: PortalLink.AdminHome },
        { kind: 'venueActivated', target: PortalLink.HostHome },
        { kind: 'final', target: redirectTo || '/host/access' },
      ]);
      if (!redirected) setInited(true);
    }
    init();
  }, [redirect, redirectTo]);

  const onLoginClick = () => {
    navigate({
      pathname: '/login',
      search: buildSearchParamsWithRedirectToAsString(window.location.href),
    });
  };

  const onSignup = async (values: FormikValues, actions: FormikValues) => {
    try {
      const res = await apiService.auth.signup({
        email: values.email,
        username: values.username,
        password: values.password,
      });
      const { user, token } = res.data;
      ctx.postLogin(token, user);
    } catch (error: UnassertedUnknown) {
      setError(error);
    }
    actions.setSubmitting(false);
  };

  const validate = (values: SignupFormValues) => {
    const errors: SignupFormValues = {};
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (!values.username) {
      errors.username = 'Username is required';
    } else if (values.username.length < 3) {
      errors.username = 'Username must be at least 3 characters';
    } else if (values.username.length > 25) {
      errors.username = 'Max character count reached';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    return errors;
  };

  if (!inited) return <></>;

  return (
    <div className='w-100 h-121 flex flex-col items-center'>
      <img src={logo} className='h-26' alt='logo' />
      <Formik<{
        email?: string;
        username?: string;
        password?: string;
      }>
        initialValues={{
          email: '',
          username: '',
          password: '',
        }}
        validate={validate}
        onSubmit={onSignup}
      >
        {({
          isSubmitting,
          errors,
          handleSubmit,
        }: FormikProps<SignupFormValues>) => {
          return (
            <form className='w-full px-7' onSubmit={handleSubmit}>
              <Field
                data-testid='signup-email'
                className={errors?.email ? 'field-error mb-1' : 'field mb-1'}
                name='email'
                placeholder='Email'
                maxLength={1000}
              />
              <div className='pl-1 h-4 text-3xs text-red-005'>
                {errors.email}
              </div>
              <Field
                data-testid='signup-username'
                className={errors?.username ? 'field-error my-1' : 'field my-1'}
                name='username'
                placeholder='Username'
                maxLength={26}
              />
              <div className='pl-1 h-4 text-3xs text-red-005'>
                {errors.username}
              </div>
              <Field
                data-testid='signup-password'
                className={errors?.password ? 'field-error my-1' : 'field my-1'}
                name='password'
                placeholder='Password'
                type='password'
                maxLength={1000}
              />
              <div className='pl-1 h-4 text-3xs text-red-005'>
                {errors.password}
              </div>
              <div className='pl-1 text-3xs my-2 text-red-005 text-center'>
                {error?.msg}
              </div>
              <button
                data-testid='signup-submit'
                type='submit'
                className='btn-primary w-full h-12 text-sms'
                disabled={isSubmitting || Object.keys(errors).length > 0}
              >
                Sign Up
              </button>
            </form>
          );
        }}
      </Formik>
      <div className='w-72 mt-3'>
        <LegalDisclaimer />
      </div>
      <div className='mt-2'>
        <p className='inline text-white text-sms'>Already have an account?</p>
        <button
          onClick={onLoginClick}
          type='button'
          className='appearance-none text-primary text-sms ml-1'
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export const SignUpModal = (): JSX.Element => {
  return (
    <Modal>
      <SignupForm />
    </Modal>
  );
};
