import { useLocation, useNavigate } from '@remix-run/react';
import { type ReactNode, useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';

import logo from '../../../assets/img/logo-text.svg';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { apiService } from '../../../services/api-service';
import { getStaticAssetPath } from '../../../utils/assets';
import { err2s } from '../../../utils/common';
import { usePostLogin } from '../../Access/hooks';
import { ArrowRightIcon } from '../../icons/Arrows';
import { SlackIcon } from '../../icons/SlackIcon';
import { Loading } from '../../Loading';
import { SlackUtils } from '../../Slack';

function Layout(props: { children?: ReactNode }): JSX.Element {
  const benefits = useInstance(() => {
    return [
      'Manage your team’s participants directly in Slack',
      'Improved tracking of engagement metrics*',
      'Tools for players to customize their experience*',
      'Better matching algorithms to increase participation*',
    ];
  });
  return (
    <div
      className='w-full h-full flex justify-between'
      style={{
        backgroundImage: `url(${getStaticAssetPath(
          'images/slack-one-click-install-bg-v2.png'
        )}`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#f3f3f0',
      }}
    >
      <div className='flex flex-col items-start justify-start ml-14 xl:ml-24 2xl:ml-32 flex-shrink-0'>
        <img src={logo} className='w-56 h-40' alt='logo' />
        <div className='font-Montserrat tracking-wide text-4.5xl'>
          <div className='text-[#23272A]'>UNLOCK SEAMLESS</div>
          <div className='text-[#23272A]'>GAMEPLAY WITH OUR</div>
          <div className='font-black text-red-002'>NEW SLACK APP</div>
        </div>
        <div className='font-Montserrat text-lg text-[#606060] mt-6 mb-2 pl-2'>
          <ul>
            {benefits.map((text, i) => (
              <li key={i} className='flex items-center justify-start'>
                <div className='mr-3'>•</div>
                {text}
              </li>
            ))}
          </ul>
          <div className='font-Montserrat text-3xs text-[#606060] italic text-right mt-4'>
            *coming soon
          </div>
        </div>
        {props.children}
      </div>
      <div className='absolute top-12 w-140 right-8 xl:right-16 xl:w-auto 2xl:right-24'>
        <img
          src={getStaticAssetPath('images/slack-one-click-install-header.png')}
          alt='header'
        />
      </div>
    </div>
  );
}

export function SlackOneTimeInstall(): JSX.Element {
  const code = useQueryParam('code');
  const postLogin = usePostLogin();
  const [leaving, setLeaving] = useState(false);

  const {
    state: { transformed: state },
    error,
    call: loginAndInstall,
  } = useAsyncCall(
    useCallback(
      async (code: string) => {
        // 1. login
        const verifyResp = await apiService.auth.verifyCallback({ code });
        postLogin(verifyResp.data);

        // 2. Slack OAuth
        window.location.href = await SlackUtils.GenerateSlackInstallURL({
          scenario: 'connect',
          redirectTo: '/slack-connected',
        });
        setLeaving(true);
      },
      [postLogin]
    )
  );

  const handleClick = async () => {
    if (!code) return;
    await loginAndInstall(code);
  };

  if (!code) {
    return <Layout>Invalid link</Layout>;
  }

  return (
    <Layout>
      <button
        className='font-Montserrat btn-primary flex items-center justify-center px-4 py-2 w-[186px] h-12'
        onClick={handleClick}
        disabled={state.isRunning || leaving}
      >
        <SlackIcon className='w-5 h-5' />
        <div className='mx-3 font-bold text-sm'>
          {leaving ? 'Please Wait' : 'Add to Slack'}
        </div>
        <ArrowRightIcon />
      </button>
      {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}
    </Layout>
  );
}

// type SlackOneTimeInstalledState =
//   | {
//       success: true;
//       attached: {
//         orgId: string;
//         binding: SlackModalBinding;
//       } | null;
//     }
//   | {
//       success: false;
//       error: string;
//     };

// function buildState(
//   state: SlackOneTimeInstalledState
// ): SlackOneTimeInstalledState {
//   return state;
// }

// function IsSlackOneTimeInstalledState(
//   state: unknown
// ): state is SlackOneTimeInstalledState {
//   if (typeof state !== 'object' || state === null) return false;
//   if (typeof state['success'] !== 'boolean') return false;
//   if (state['success']) {
//     return 'attached' in state;
//   } else {
//     return 'error' in state;
//   }
// }

export function SlackOneTimeInstalled(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<unknown>(null);

  useEffectOnce(() => {
    async function run() {
      if (!SlackUtils.IsSlackInstalledState(location.state)) return;
      if (location.state.success) {
        try {
          navigate('/home', {
            state: location.state,
            replace: true,
          });
        } catch (error) {
          setError(error);
        }
      } else {
        navigate('/home', {
          state: location.state,
          replace: true,
        });
      }
    }
    run();
  });
  return (
    <div className='w-full h-full text-white flex flex-col items-center justify-center'>
      {error ? (
        <div className='text-red-002 text-sms'>{err2s(error)}</div>
      ) : (
        <Loading text='Please Wait...' />
      )}
    </div>
  );
}
