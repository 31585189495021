import logoText from '../../assets/img/logo-text.svg';
import randomGroupings from '../../assets/img/random-groupings.png';
import scheduledActivities from '../../assets/img/scheduled-activities.png';
import spontaneousHangouts from '../../assets/img/spontaneous-hangouts.png';

function MarketingBullet(props: {
  imageSrc: string;
  title: string;
  description: string;
}): JSX.Element {
  return (
    <div className='flex flex-row-reverse items-center gap-7.5'>
      <img src={props.imageSrc} alt={props.title} className='w-20 h-20' />
      <div className='flex flex-col gap-2'>
        <div className='text-white font-medium'>{props.title}</div>
        <div className='text-white text-sms'>{props.description}</div>
      </div>
    </div>
  );
}

function MarketingSidebar(): JSX.Element {
  return (
    <div
      className={`
        h-full w-full
        flex-shrink-0
        bg-dark-gray
        hidden
        lg:flex lg:flex-col lg:w-108
        xl:w-125
      `}
    >
      <header className='p-10 pb-0 xl:p-16 xl:pb-0 space-y-4'>
        <img src={logoText} className='w-40 -my-4' alt='Luna Park Logo' />

        <div className='text-white font-bold text-2xl'>
          Welcome to the world’s first gaming platform for teams.
        </div>
      </header>

      <section className='p-10 xl:p-16 vh-sm:hidden'>
        <div className='text-white text-md font-bold'>
          Build deeper connections on Luna Park with our growing library of
          collaborative games.
        </div>

        <div className='mt-7.5 flex flex-col gap-12.5'>
          <MarketingBullet
            imageSrc={spontaneousHangouts}
            title='Spontaneous Hangouts'
            description='Perfect for team building, lunch get togethers, and all team hangouts.'
          />

          <MarketingBullet
            imageSrc={scheduledActivities}
            title='Scheduled Activities'
            description='Integrate into your current standing meetings like Standup, Team Syncs, etc.'
          />

          <MarketingBullet
            imageSrc={randomGroupings}
            title='Randomized Groupings'
            description='Build new relationships through randomized group competitions.'
          />
        </div>
      </section>
    </div>
  );
}

export function MarketingLayout(props: {
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <div className='w-full h-full flex'>
      <MarketingSidebar />

      <div
        className={`
          h-full w-full
          bg-layer-001 bg-user-activation
          bg-no-repeat bg-contain bg-bottom
          overflow-hidden
          relative
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
