import { useNavigate } from '@remix-run/react';
import axios from 'axios';
import { useEffectOnce } from 'react-use';

import { EnumsOAuthProvider } from '@lp-lib/api-service-client/public';

import { useAuthAnalytics } from '../../analytics/auth';
import config from '../../config';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { assertExhaustive } from '../../utils/common';
import { GlobalLoading } from '../GlobalLoading';
import { buildSearchParamsWithRedirectToAsString, usePostLogin } from './hooks';

const REDIRECT_URI = () => window.location.origin + '/oauth-callback';
export type OAuthScenario = 'register' | 'login';

export type OAuthState = {
  provider: EnumsOAuthProvider;
  search?: string;
} & (
  | {
      scenario: 'register';
      organizationName?: string;
      useEmailAsOrgName?: boolean;
    }
  | {
      scenario: 'login';
    }
);

export class OAuthUtils {
  static Encode(state: OAuthState): string {
    return Buffer.from(JSON.stringify(state)).toString('base64');
  }
  static Decode(encoded: string): OAuthState {
    return JSON.parse(Buffer.from(encoded, 'base64').toString('utf8'));
  }
  static GetURL(state: OAuthState): string {
    const encodedState = OAuthUtils.Encode(state);
    switch (state.provider) {
      case EnumsOAuthProvider.OAuthProviderGoogle:
        return `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(
          {
            response_type: 'code',
            scope: 'openid profile email',
            client_id: config.misc.googleClientId,
            redirect_uri: REDIRECT_URI(),
            state: encodedState,
            access_type: 'offline',
          }
        ).toString()}`;
      case EnumsOAuthProvider.OAuthProviderSlack:
        if (!config.slack.clientId) throw new Error('Slack client ID not set');

        return `https://slack.com/openid/connect/authorize?${new URLSearchParams(
          {
            response_type: 'code',
            scope: 'openid profile email',
            client_id: config.slack.clientId,
            state: encodedState,
            redirect_uri: REDIRECT_URI(),
          }
        ).toString()}`;
      default:
        assertExhaustive(state.provider);
        return '';
    }
  }
}

export function OAuthCallback(): JSX.Element {
  const analytics = useAuthAnalytics();
  const navigate = useNavigate();
  const postLogin = usePostLogin();

  useEffectOnce(() => {
    const err = getQueryParam('error');
    const code = getQueryParam('code');
    const rawState = getQueryParam('state');
    if (!rawState) return;
    const state = OAuthUtils.Decode(rawState);
    const { provider, scenario, search } = state;
    const searchPrams = new URLSearchParams(search);
    const redirectTo = searchPrams.get('redirect-to') || '/home';

    analytics.trackOAuthCallback({
      err,
      state,
    });

    // user reject the auth
    if (err) {
      switch (scenario) {
        case 'register':
          navigate({
            pathname: '/register',
            search: search,
          });
          break;
        case 'login':
          navigate({
            pathname: '/login',
            search: search,
          });
          break;
        default:
          assertExhaustive(scenario);
      }
      return;
    }

    const run = async () => {
      if (!code) return;
      switch (scenario) {
        case 'register':
          try {
            const registerResp = await apiService.auth.oauthRegister({
              provider,
              redirectURI: REDIRECT_URI(),
              code,
              organizationName: state.organizationName,
              useEmailAsOrgName: state.useEmailAsOrgName,
              queries: Object.fromEntries(searchPrams.entries()),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
            postLogin(registerResp.data);
            navigate(
              {
                pathname: '/onboarding/headcount',
                search: search,
              },
              {
                replace: true,
              }
            );
          } catch (error) {
            const apiErr = apiService.utils.CastAPIError(error);
            if (apiErr?.msg === 'email_already_registered') {
              navigate(
                {
                  pathname: '/login',
                  search: buildSearchParamsWithRedirectToAsString(redirectTo),
                },
                {
                  state: {
                    error:
                      'Oops! That email address is already associated with a Luna Park account. You can login below!',
                  },
                }
              );
            } else {
              throw error;
            }
          } finally {
            break;
          }
        case 'login':
          try {
            const loginResp = await apiService.auth.oauthLogin({
              provider: provider,
              redirectURI: REDIRECT_URI(),
              code,
              loginFrom: redirectTo,
            });
            postLogin(loginResp.data);
            window.location.replace(redirectTo);
            break;
          } catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 404) {
              const email = e.response.data?.['data']?.['email'];
              window.location.replace(
                `/login${buildSearchParamsWithRedirectToAsString(redirectTo, {
                  'user-not-found': email,
                })}`
              );
            } else {
              throw e;
            }
          }
      }
    };

    run();
  });

  return <GlobalLoading />;
}
