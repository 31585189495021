export const GoogleIcon = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='23'
      viewBox='0 0 23 23'
      className={props.className}
    >
      <path
        fillRule='evenodd'
        d='M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23 23 17.851 23 11.5 17.851 0 11.5 0zm.134 18.226c-3.706 0-6.709-3.01-6.709-6.726s3.003-6.726 6.71-6.726c1.81 0 3.324.668 4.486 1.753L14.23 8.423v-.004c-.705-.673-1.598-1.018-2.596-1.018-2.214 0-4.012 1.875-4.012 4.095 0 2.22 1.798 4.098 4.012 4.098 2.009 0 3.376-1.151 3.657-2.732h-3.657v-2.623h6.31c.085.45.13.92.13 1.412 0 3.842-2.565 6.575-6.44 6.575z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export function ColoredGoogleIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      className={props.className}
    >
      <path
        fill='#FFC107'
        d='M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z'
      ></path>
      <path
        fill='#FF3D00'
        d='M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z'
      ></path>
      <path
        fill='#4CAF50'
        d='M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0124 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z'
      ></path>
      <path
        fill='#1976D2'
        d='M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 01-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z'
      ></path>
    </svg>
  );
}
