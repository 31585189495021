import './assets/css/index.css';
import './assets/scss/main.scss';

import { Route } from '@remix-run/react';
import { Navigate, Outlet, Routes } from '@remix-run/react';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { useUserAnalytics } from './analytics/user';
import {
  AdminAccess,
  AdminAccessDenied,
  LoginModal,
  OAuthCallback,
  RequireActivation,
  RequireToken,
  SignUpModal,
  UserActivation,
  UserVerifyModel,
} from './components/Access';
import { GlobalBlockedAccess } from './components/Access/GlobalBlocked';
import { MobileAccessDenied } from './components/Access/MobileAccessDenied';
import { UserAccess } from './components/Access/UserAccess';
import {
  SlackOneTimeInstall,
  SlackOneTimeInstalled,
} from './components/Channel';
import { GlobalLoading } from './components/GlobalLoading';
import { PairingLandingPage } from './components/Pairing';
import { UserContextProvider } from './components/UserContext';
import config from './config';
import { Error404Page, ErrorPage } from './Error';
import { useBootIntercom } from './hooks/useBootIntercom';
import { useInstance } from './hooks/useInstance';
import { useLoggerConsoleCtrl } from './logger/logger-ctrl';
import { CrossStorageHub } from './pages/CrossStorage';
import { LoginStateSyncer } from './pages/CrossStorage/LoginStateSyncer';
import { store } from './store/configureStore';
import { setAPIServiceClientSecureToken } from './utils/setAPIClientToken';

setAPIServiceClientSecureToken();

const Audience = React.lazy(() => import('./pages/Audience'));
const MobileAudience = React.lazy(() => import('./pages/Audience/MobileVenue'));
const Host = React.lazy(() => import('./pages/Host'));
const Admin = React.lazy(() => import('./pages/Admin'));
const ConnectionTest = React.lazy(() => import('./pages/ConnectionTest'));
const CloudHost = React.lazy(() => import('./pages/CloudHost'));
const LeaderboardContainer = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({
    default: m.LeaderboardContainer,
  }))
);
const LeaderboardHome = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({ default: m.LeaderboardHome }))
);
const LeaderboardDetail = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({ default: m.LeaderboardDetail }))
);
const MyMemories = React.lazy(() => import('./pages/Analytics/MyMemories'));
const Media = React.lazy(() => import('./pages/Media'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const EventPlay = React.lazy(() => import('./pages/Events/MyEventPlay'));
const EventCohost = React.lazy(() => import('./pages/Events/EventCohost'));
const EventList = React.lazy(() => import('./pages/Events/MyEventList'));
const EventDetail = React.lazy(() => import('./pages/Events/MyEventDetail'));
const OnboardTaskDetail = React.lazy(() => import('./pages/Onboard/Task'));
const Live = React.lazy(() => import('./pages/Live'));
const ProgramHome = React.lazy(() => import('./pages/Program'));
const ChannelHome = React.lazy(() => import('./pages/Channel'));
const Memories = React.lazy(() => import('./pages/Memories'));

const NotificationSettings = React.lazy(() => import('./pages/Notification'));

/**
 * Container element for routes that should be blocked.
 */
function BlockableRouteContainer(): JSX.Element {
  return (
    <GlobalBlockedAccess>
      <Outlet />
    </GlobalBlockedAccess>
  );
}

/**
 * Container element for routes that should require user activation.
 */
function RequireActivationContainer(): JSX.Element {
  return (
    <RequireActivation>
      <Outlet />
    </RequireActivation>
  );
}

function AppInit() {
  useBootIntercom(
    useInstance(() => [
      '/home',
      '/live',
      '/events',
      '/programs',
      '/channels',
      '/stats/me',
      '/analytics',
    ])
  );
  return (
    <Routes>
      <Route
        element={
          <LoginStateSyncer
            enabled={config.crossStorage.enabled}
            origin={config.crossStorage.origin}
          />
        }
      >
        <Route
          path='/m/venue/:vid/*'
          element={
            <Suspense fallback={null}>
              <MobileAudience />
            </Suspense>
          }
        />
        <Route element={<BlockableRouteContainer />}>
          {/* users should be able to play games without activation */}
          <Route
            path='/venue/:vid/*'
            element={
              <Suspense fallback={null}>
                <Audience />
              </Suspense>
            }
          />
          <Route
            path='/events/:eventId/play'
            element={
              <Suspense fallback={<GlobalLoading />}>
                <EventPlay />
              </Suspense>
            }
          />

          <Route element={<RequireActivationContainer />}>
            <Route path='/' element={<Navigate to='/home' />} />

            <Route
              path='/cloud-host/venue/:vid/*'
              element={
                <Suspense fallback={<GlobalLoading />}>
                  <CloudHost />
                </Suspense>
              }
            />

            <Route element={<RequireToken loginType='password' />}>
              <Route
                path='/host/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Host />
                  </Suspense>
                }
              />
              <Route
                path='/admin/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <AdminAccess>
                      <Admin />
                    </AdminAccess>
                  </Suspense>
                }
              />
            </Route>

            <Route element={<UserAccess />}>
              <Route
                path='/live'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Live />
                  </Suspense>
                }
              />
              <Route
                path='/pairings/:pairingId'
                element={<PairingLandingPage />}
              />
              <Route
                path='/leaderboards/:roundId'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <LeaderboardContainer />
                  </Suspense>
                }
              >
                <Route index element={<LeaderboardHome />} />
                <Route
                  path='round'
                  element={<LeaderboardDetail type='round' />}
                />
                <Route
                  path='global'
                  element={<LeaderboardDetail type='global' />}
                />
              </Route>
              <Route
                path='/memories'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <MyMemories />
                  </Suspense>
                }
              />
              <Route
                path='/tournaments/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Navigate to='/channels' replace />;
                  </Suspense>
                }
              />
              <Route
                path='/channels/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ChannelHome />
                  </Suspense>
                }
              />
              <Route
                path='/analytics/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Analytics />
                  </Suspense>
                }
              />
              <Route
                path='/events'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventList />
                  </Suspense>
                }
              />
              <Route
                path='/events/:eventId'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventDetail />
                  </Suspense>
                }
              />
              <Route
                path='/events/:eventId/cohost'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventCohost />
                  </Suspense>
                }
              />
              <Route
                path='/onboarding/tasks/:taskId'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <OnboardTaskDetail />
                  </Suspense>
                }
              />
              <Route
                path='/programs/*'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ProgramHome />
                  </Suspense>
                }
              />

              <Route
                path='/u/connection-test'
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ConnectionTest />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* note: memories page allows guest user access. */}
        <Route element={<RequireToken />}>
          <Route
            path='/sessions/:sessionId/memories'
            element={
              <Suspense fallback={<GlobalLoading />}>
                <Memories />
              </Suspense>
            }
          />
        </Route>

        <Route path='/oauth-callback' element={<OAuthCallback />} />
        <Route path='/signup' element={<SignUpModal />} />
        <Route path='/login' element={<LoginModal />} />
        <Route
          path='/user-verify'
          element={<UserVerifyModel verifyType='login' />}
        />
        <Route
          path='/user-join'
          element={<UserVerifyModel verifyType='invite' />}
        />
        <Route path='/user-active' element={<UserActivation />} />
        <Route path='/slack-connect' element={<SlackOneTimeInstall />} />
        <Route path='/slack-connected' element={<SlackOneTimeInstalled />} />
        <Route path='/error' element={<ErrorPage />} />
        <Route
          path='/connection-test'
          element={
            <Suspense fallback={<GlobalLoading />}>
              <ConnectionTest />
            </Suspense>
          }
        />
        <Route path='/mobile-access-denied' element={<MobileAccessDenied />} />
        <Route path='/access-denied' element={<AdminAccessDenied />} />
        <Route
          path='/storage-hub'
          element={<CrossStorageHub origin={config.crossStorage.origin} />}
        />
        <Route
          path='/notifications/settings'
          element={
            <Suspense fallback={<GlobalLoading />}>
              <NotificationSettings />
            </Suspense>
          }
        />
      </Route>
      <Route
        path='/media/:mediaId'
        element={
          <Suspense fallback={<GlobalLoading />}>
            <Media />
          </Suspense>
        }
      />
      <Route path='*' element={<Error404Page capture />} />
    </Routes>
  );
}

function AppRoutes() {
  useLoggerConsoleCtrl();
  return (
    <Provider store={store}>
      <UserContextProvider useUserAnalytics={useUserAnalytics}>
        <IntercomProvider appId={config.misc.intercomAppId}>
          <AppInit />
        </IntercomProvider>
      </UserContextProvider>
    </Provider>
  );
}

export const Component = AppRoutes;
